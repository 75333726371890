@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";
@import "./variable.less";

@font-family:
  MulishVariableFont,
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  "segoe ui",
  Roboto,
  Helvetica,
  Arial,
  sans-serif;

@primary-color: #2c80f0;
@outline-color: @c-l1;
// @border-radius-base: 10px;
// table
//@table-header-cell-split-color: transparent;
//#region v4 antd样式修改 变量部分

// layout
@layout-body-background: #f7f8fa;
@layout-header-background: #f7f8fa;
@layout-header-height: 90px;

// alert
@alert-info-bg-color: @c-gc;
@alert-info-border-color: @c-gc;
@alert-message-color: @c-l2;

// badge
@badge-font-size: 14px;

// button
@btn-border-radius-base: 4px;
@btn-border-radius-sm: 4px;
@btn-shadow: none;
@btn-primary-shadow: 0 0 3px rgba(161, 161, 161, 0.35);

@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;

@btn-padding-horizontal-base: 16px;
@btn-padding-horizontal-lg: 20px;
@btn-padding-horizontal-sm: 12px;

@btn-font-size-lg: @fs-l;
@btn-font-size-sm: @fs-s;

@btn-default-color: @c-d3;
@btn-default-bg: @c-w;
@btn-default-border: @c-gb;

@btn-primary-color: @c-w;
@btn-primary-bg: @c-b;

// input
@input-height-base: @height-base;
@input-height-lg: @height-lg;
@input-height-sm: @height-sm;

@input-padding-horizontal-base: 10px;
@input-padding-horizontal-sm: 10px;
@input-padding-horizontal-lg: 12px;
@input-padding-vertical-base: 5px;
@input-padding-vertical-sm: 1px;
@input-padding-vertical-lg: 10px;

@input-placeholder-color: @c-l1;
@input-color: @c-d2;
@input-border-color: @c-gb;
@input-hover-border-color: @c-l1;

// select
@select-single-item-height-lg: @height-lg;

// radio
@radio-size: 24px;
@radio-top: 0;

// radio button
@radio-button-checked-bg: @c-b;

// checkbox
@checkbox-size: 24px;

// tabs
@tabs-horizontal-padding: 0 0 14px;
@tabs-horizontal-padding-lg: 0 0 22px;
@tabs-horizontal-gutter: 30px;
@tabs-ink-bar-color: @c-b;
@tabs-bar-margin: 0;

@tabs-highlight-color: @c-d1;

@tabs-title-font-size: @fs-l;
@tabs-title-font-size-lg: @fs-l;
@tabs-title-font-size-sm: @fs-l;

@tabs-vertical-padding: 0 30px 0px 0;
@tabs-vertical-margin: 16px 0 0 0;

// rate
@rate-star-color: @c-b;

// modal
@modal-header-padding: 24px 0px;
@modal-header-title-font-size: @fs-l;
@modal-header-close-size: 70px;

@modal-body-padding: 30px 0;
@modal-confirm-body-padding: 30px 60px;

// drawer
@drawer-header-padding: 24px 0px;
@drawer-title-font-size: @fs-l;
@drawer-header-close-size: 70px;

@drawer-body-padding: 30px 0;

// table
@table-header-bg: #fff;
@table-header-color: @c-l2;
@table-header-cell-split-color: transparent;

@table-border-color: @c-gs;
@table-row-hover-bg: @c-gc;

@table-font-size: 13px;
//#endregion

// Tabs
// .ant-tabs {
//   > .ant-tabs-nav {
//     padding: 0 30px;
//     margin-bottom: 0;
//     background-color: #fff;
//     // 路由tab样式
//     .ant-tabs-tab a {
//       color: rgba(0, 0, 0, 0.65);
//     }
//     .ant-tabs-tab.ant-tabs-tab-active a {
//       color: #2c80f0;
//     }
//   }
//   > .ant-tabs-content-holder {
//     padding: 24px 30px 0;
//   }

//   &.no-tab-padding > .ant-tabs-nav {
//     padding: 0;
//   }

//   &.no-content-padding > .ant-tabs-content-holder {
//     padding: 0;
//   }
// }
// .ant-tabs.ant-tabs-card {
//   .ant-tabs-nav {
//     background-color: transparent;
//   }
// }
// .ant-tabs-nav .ant-tabs-tab {
//   height: auto;
// }

// Steps
.ant-steps-item-icon > .ant-steps-icon {
  line-height: inherit;
}

// Icon
.anticon {
  // line-height: inherit;
}

// Input group
//.ant-input-affix-wrapper .ant-input {
//  min-height: inherit;
//}

// Input boxshadow
//.ant-input:focus {
//  box-shadow: none;
//}

// label
//.ant-form-item-label label {
//  color: #111112;
//  font-size: 12px;
//}

// select

// .ant-select {
//   &-borderless .ant-select-selector {
//     background-color: #fff !important;
//   }
// }

.ant-select-dropdown-menu-item {
  &:hover {
    color: #2c80f0;
    background-color: #fff;
  }
}

// tooltip
.ant-tooltip {
  max-width: none;
}

// popover
.ant-popover {
  .ant-popover-inner {
    box-shadow: 0 -2px 10px 0 #eee;

    .ant-popover-title {
      height: 50px;
      font-size: 14px;
      color: #666666;
      padding: 16px 20px;
      border-bottom: 1px solid #eee;
    }

    .ant-popover-inner-content {
      padding: 16px 20px;
      font-size: 12px;
      color: #a2a2a2;
    }
  }
}

// modal ui修改
// .ant-modal-header {
//   .ant-modal-title {
//     font-size: 14px;
//     color: #111112;
//     letter-spacing: 0;
//   }
// }

// .ant-modal-content {
//   border-radius: 8px;

//   .ant-modal-confirm-body > .anticon {
//     font-size: 38px;
//   }
//   .ant-modal-confirm-body .ant-modal-confirm-title {
//   }
//   .ant-modal-confirm-body
//     > .anticon
//     + .ant-modal-confirm-title
//     + .ant-modal-confirm-content {
//     margin-left: 54px;
//     margin-top: 20px;
//   }
// }

// table
//.ant-table-tbody {
//}
//
//
//.ant-table-thead > tr > th {
//  background-color: #ddd;
//}

// menu
.ant-menu-inline {
  border-right: none;
}

.ant-menu-vertical > .ant-menu-submenu.ant-menu-submenu-selected > .ant-menu-submenu-title {
}
