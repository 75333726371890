@charset "utf-8";
/**
 * @module 背景与边框
 * @description 定义圆角，用于修复某些安卓机型上“圆角+边框+背景”，背景溢出的情况
 * @method border-radius
 * @version 1.6.0
 * @param {Length} $border-radius 指定元素的圆角半径，取值与`border-radius`属性一致 <1.6.0>
 */
@mixin border-radius($border-radius...) {
    border-radius: $border-radius;
    // 之所以会这样是因为这些机型的背景是从边框处开始渲染，所以只需要改成从padding处渲染即可
    @include background-clip(padding-box !important);
}