@layer components {
  // 标题
  .text-xxl {
    @apply text-c-d1;
    font-size: 20px;
    line-height: 26px;
  }

  .text-xl {
    @apply text-c-d1;
    font-size: 18px;
    line-height: 24px;
  }

  .text-l {
    @apply text-c-d2;
    font-size: 16px;
    line-height: 22px;
  }

  .text-m {
    @apply text-c-d2;
    font-size: 14px;
    line-height: 20px;
  }

  .text-s {
    @apply text-c-d3;
    font-size: 13px;
    line-height: 18px;
  }

  .text-xs {
    @apply text-c-d3;
    font-size: 12px;
    line-height: 18px;
  }

  .text-link {
    @apply text-primary cursor-pointer font-normal;
    font-size: 13px;
  }

  .text-danger {
    @apply cursor-pointer text-c-r;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ellipsis-line-2 {
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-line-clamp: 2; /* 2行，只有 webkit内核支持 */
    word-break: break-all; /* 纯英文换行 */
    overflow: hidden;
  }
  .ellipsis-line-3 {
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-line-clamp: 3; /* 2行，只有 webkit内核支持 */
    word-break: break-all; /* 纯英文换行 */
    overflow: hidden;
  }
}
