// Auto Generated By tailwind.config.js. 
// version: 4.0
$c-d1: #272b30;
$c-d2: #34383c;
$c-d3: #586167;
$c-l2: #8D959F;
$c-l1: #B6BABF;
$c-w: #FFFFFF;
$c-b: #2C80F0;
$c-o: #FF8846;
$c-o1: #BD4A0A;
$c-r: #FF391E;
$c-bl: #EBF2F9;
$c-gc: #F9F9F9;
$c-gs: #E1E1E1;
$c-gb: #E2E5E9;
$fs-xxl: 20px;
$fs-xl: 18px;
$fs-l: 16px;
$fs-m: 14px;
$fs-s: 13px;
$fs-xs: 12px;
$height-base: 32px;
$height-lg: 42px;
$height-sm: 24px;
