@charset "utf-8";
/**
 * @module Flexbox
 * @description 定义显示类型为伸缩盒
 * @method flexbox
 * @version 1.0.0
 * @param {String} $flexbox 默认值：flex，可选值：flex | inline-flex <1.0.0>
 */
@mixin flexbox($flexbox: flex) {
  @if $flexbox == inline-flex {
    $flexbox: "inline-";
  } @else {
    $flexbox: "";
  }
  // 老式浏览器（实验性质支持3个阶段草案）
  // 原始草案：20090723
  // 过渡草案：20110322-20120322（以后面这个日期为准）
  // 最新草案：20120612-20140925（以后面这个日期为准）
  // @if map-get($setting, is-vendor-prefix) {
  //     @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //             // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //             display: #{$vendor}#{$flexbox}box;
  //             display: #{$vendor}#{$flexbox}flex;
  //         } @else {
  //             // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //             display: #{$vendor}#{$flexbox}flexbox;
  //         }
  //     }
  // }
  // 现代浏览器（支持最新草案）
  display: #{$flexbox}flex;
}

/**
 * @module Flexbox
 * @description 定义伸缩盒子元素如何分配空间
 * @method flex
 * @version 1.0.0
 * @param {Number} $flex 取值与`flex`属性一致，默认值：1 <1.0.0>
 * @param {String} $direction 默认值: row，可选值：row | column <1.5.0>
 */
@mixin flex($flex: 1, $direction: row) {
  // 老式浏览器（实验性质支持3个阶段草案）
  // 原始版本box-flex并不是复合属性，所以只有一个为数字的值
  //   @if map-get($setting, is-vendor-prefix) {
  //     @each $vendor in map-get($setting, vendor-prefix) {
  //       @if $vendor != -ms- {
  //         // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //         #{$vendor}box-flex: $flex;
  //         #{$vendor}flex: $flex;
  //       } @else {
  //         // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //         #{$vendor}flex: $flex;
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  flex: $flex;
  // 修复Android Browser4.3及以下，iOS Safari6.1及以下按比例分配错误的问题（同时还包括flex嵌套时分配错误）
  // @if $direction == row {
  //     width: .1px;
  // } @else {
  //     height: .1px;
  // }
}

/**
 * @module Flexbox
 * @description 定义伸缩盒子元素的排版顺序
 * @method order
 * @version 1.0.0
 * @param {Integer} $order 取值与`order`属性一致，默认值：1 <1.0.0>
 */
@mixin order($order: 1) {
  // 老式浏览器（实验性质支持3个阶段草案）
  //   @if map-get($setting, is-vendor-prefix) {
  //     @each $vendor in map-get($setting, vendor-prefix) {
  //       @if $vendor != -ms- {
  //         // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //         #{$vendor}box-ordinal-group: $order;
  //         #{$vendor}order: $order;
  //       } @else {
  //         // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //         #{$vendor}flex-order: $order;
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  order: $order;
}

/**
 * @module Flexbox
 * @description 定义弹性盒子元素流动方向及遇见边界时是否换行(要求系统：iOS7.0+, Android4.4+)
 * @method flex-flow
 * @version 2.0.0
 * @param {String} $flex-flow 取值与`flex-flow`属性一致，默认值：row nowrap <2.0.0>
 */
@mixin flex-flow($flex-flow: row nowrap) {
  // 老式浏览器（实验性质支持过渡和最新2个阶段草案）+ 现代浏览器
  @include prefix(flex-flow, $flex-flow);
}

/**
 * @module Flexbox
 * @description 定义伸缩盒子元素的流动方向
 * @method flex-direction
 * @version 1.0.0
 * @param {String} $flex-direction 取值与`flex-direction`属性一致，默认值：row <1.0.0>
 */
@mixin flex-direction($flex-direction: row) {
  // 老式浏览器（实验性质支持原始草案）
  // 当厂商前缀不为`-ms-`时输出原始草案厂商前缀版
  //   @if $flex-direction == row {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           #{$vendor}box-orient: horizontal;
  //           #{$vendor}box-direction: normal;
  //         }
  //       }
  //     }
  //   } @else if $flex-direction == column {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           #{$vendor}box-orient: vertical;
  //           #{$vendor}box-direction: normal;
  //         }
  //       }
  //     }
  //   } @else if $flex-direction == row-reverse {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           #{$vendor}box-orient: horizontal;
  //           #{$vendor}box-direction: reverse;
  //         }
  //       }
  //     }
  //   } @else if $flex-direction == column-reverse {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           #{$vendor}box-orient: vertical;
  //           #{$vendor}box-direction: reverse;
  //         }
  //       }
  //     }
  //   }
  //   // 老式浏览器（实验性质支持过渡及最新草案）
  //   @if map-get($setting, is-vendor-prefix) {
  //     @each $vendor in map-get($setting, vendor-prefix) {
  //       // `flex-direction`属性在过渡和最新草案中语法一致
  //       #{$vendor}flex-direction: $flex-direction;
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  flex-direction: $flex-direction;
}

/**
 * @module Flexbox
 * @description 定义弹性盒子元素溢出后排版(要求系统：iOS7.0+, Android4.4+)
 * @method flex-wrap
 * @version 1.0.0
 * @param {String} $flex-wrap 取值与`flex-wrap`属性一致，默认值：nowrap <1.0.0>
 */
@mixin flex-wrap($flex-wrap: nowrap) {
  // 老式浏览器（实验性质支持过渡和最新2个阶段草案）+ 现代浏览器
  // 原始草案有`box-lines`对应本属性，不过虽然被webkit实验性质支援，但却未被任何浏览器实现（等同于未实现）
  @include prefix(flex-wrap, $flex-wrap);
}

/**
 * @module Flexbox
 * @description 定义弹性容器主轴对齐方式(其中`space-around`值需要iOS7.0+,Android4.4+)
 * @method justify-content
 * @version 1.0.0
 * @param {String} $justify-content 取值与`justify-content`属性一致，默认值：center <1.0.0>
 */
@mixin justify-content($justify-content: center) {
  // 老式浏览器（实验性质支持3个阶段草案）
  //   @if $justify-content == center {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-pack: $justify-content;
  //           #{$vendor}justify-content: $justify-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-pack: $justify-content;
  //         }
  //       }
  //     }
  //   } @else if $justify-content == flex-start {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-pack: start;
  //           #{$vendor}justify-content: $justify-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-pack: start;
  //         }
  //       }
  //     }
  //   } @else if $justify-content == flex-end {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-pack: end;
  //           #{$vendor}justify-content: $justify-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-pack: end;
  //         }
  //       }
  //     }
  //   } @else if $justify-content == space-between {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-pack: justify;
  //           #{$vendor}justify-content: $justify-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-pack: justify;
  //         }
  //       }
  //     }
  //   } @else if $justify-content == space-around {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           // 原始草案不支持`space-around`(过渡版本中的`distribute`) 值
  //           //#{$vendor}box-pack: distribute;
  //           #{$vendor}justify-content: $justify-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-pack: distribute;
  //         }
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  justify-content: $justify-content;
}

/**
 * @module Flexbox
 * @description 定义多行弹性容器侧轴对齐方式(要求系统：iOS7.0+,Android4.4+)
 * @method align-content
 * @version 1.8.5
 * @param {String} $align-content 取值与`align-content`属性一致，默认值：center <1.8.5>
 */
@mixin align-content($align-content: center) {
  // 老式浏览器（实验性质支持2个阶段草案）
  //   @if $align-content == flex-start {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-content: $align-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-line-pack: start;
  //         }
  //       }
  //     }
  //   } @else if $align-content == flex-end {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-content: $align-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-line-pack: end;
  //         }
  //       }
  //     }
  //   } @else if $align-content == space-between {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-content: $align-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-line-pack: justify;
  //         }
  //       }
  //     }
  //   } @else if $align-content == space-around {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-content: $align-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-line-pack: distribute;
  //         }
  //       }
  //     }
  //   } @else if $align-content == center or $align-content == stretch {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-content: $align-content;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-line-pack: $align-content;
  //         }
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  align-content: $align-content;
}

/**
 * @module Flexbox
 * @description 定义单行弹性容器侧轴对齐方式
 * @method align-items
 * @version 1.0.0
 * @param {String} $align-items 取值与`align-items`属性一致，默认值：center <1.0.0>
 */
@mixin align-items($align-items: center) {
  // 老式浏览器（实验性质支持3个阶段草案）
  //   @if $align-items == flex-start {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-align: start;
  //           #{$vendor}align-items: $align-items;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-align: start;
  //         }
  //       }
  //     }
  //   } @else if $align-items == flex-end {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-align: end;
  //           #{$vendor}align-items: $align-items;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-align: end;
  //         }
  //       }
  //     }
  //   } @else {
  //     // 取值为center | baseline | stretch时，3个阶段草案均相同
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输出原始和最新草案厂商前缀版
  //           #{$vendor}box-align: $align-items;
  //           #{$vendor}align-items: $align-items;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-align: $align-items;
  //         }
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  align-items: $align-items;
}

/**
 * @module Flexbox
 * @description 定义弹性容器中子元素自身的在侧轴对齐方式(要求系统：iOS7.0+,Android4.4+)
 * @method align-self
 * @version 1.0.0
 * @param {String} $align-self 取值与`align-self`属性一致，默认值：center <1.0.0>
 */
@mixin align-self($align-self: center) {
  // 老式浏览器（实验性质支持3个阶段草案）
  //   @if $align-self == flex-start {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-self: $align-self;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-item-align: start;
  //         }
  //       }
  //     }
  //   } @else if $align-self == flex-end {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-self: $align-self;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-item-align: end;
  //         }
  //       }
  //     }
  //   } @else if
  //     $align-self ==
  //     auto or
  //     $align-self ==
  //     center or
  //     $align-self ==
  //     baseline or
  //     $align-self ==
  //     stretch
  //   {
  //     @if map-get($setting, is-vendor-prefix) {
  //       @each $vendor in map-get($setting, vendor-prefix) {
  //         @if $vendor != -ms- {
  //           // 当厂商前缀不为`-ms-`时输最新草案厂商前缀版（原始草案没有类似属性）
  //           #{$vendor}align-self: $align-self;
  //         } @else {
  //           // 当厂商前缀为`-ms-`时输出过渡草案厂商前缀版
  //           #{$vendor}flex-item-align: $align-self;
  //         }
  //       }
  //     }
  //   }
  // 现代浏览器（支持最新草案）
  align-self: $align-self;
}

/**
 * Turns off the display of an element
 *
 * 隐藏元素
 */
.d-none {
  display: none !important;
}

/**
 * The element generates a block element box, generating line breaks both before and after the element when in the normal flow.
 *
 * 块级元素，此元素前后会带有换行符
 */
.d-block {
  display: block !important;
}

/**
 * The element generates one or more inline element boxes that do not generate line breaks before or after themselves
 *
 * 内联元素，元素前后没有换行符
 */
.d-inline-block {
  display: inline-block !important;
}

/**
 * The element behaves like a block element and lays out its content according to the flexbox model
 *
 * 设置弹性盒模型 `flex`
 */
.d-flex {
  display: flex !important;
}

/**
 * The element behaves like an inline element and lays out its content according to the flexbox model
 *
 * 设置弹性盒模型 `flex`，并使用内联元素
 */
// .d-inline-flex {
//   display: inline-flex !important;
// }

/**
 * Pack flex items from the start
 *
 * 设置弹性容器主轴为：从行首起始位置开始排列
 */
// .justify-content-start {
//   justify-content: flex-start !important;
// }

/**
 * Pack flex items from the end
 *
 * 设置弹性容器主轴为：从行尾位置开始排列
 */
// .justify-content-end {
//   justify-content: flex-end !important;
// }

/**
 * Pack items around the center
 *
 * 设置弹性容器主轴为：居中排列
 */
// .justify-content-center {
//   justify-content: center !important;
// }

/**
 * Distribute items evenly, The first item is flush with the start, the last is flush with the end
 *
 * 设置弹性容器主轴为：均匀排列每个元素，首个元素放置于起点，末尾元素放置于终点
 */
// .justify-content-between {
//   justify-content: space-between !important;
// }

/**
 * Distribute items evenly, Items have a half-size space on either end
 *
 * 设置弹性容器主轴为：均匀排列每个元素，每个元素周围分配相同的空间
 */
// .justify-content-around {
//   justify-content: space-around !important;
// }

/**
 * Equivalent to 'start'. Note that justify-items is ignored in Flexbox layouts.
 *
 * 设置弹性容器轴线对齐方式为：从行首起始位置开始排列
 */
// .align-items-start {
//   align-items: flex-start !important;
// }

/**
 * Equivalent to 'end'. Note that justify-items is ignored in Flexbox layouts.
 *
 * 设置弹性容器轴线对齐方式为：从行尾位置开始排列
 */
// .align-items-end {
//   align-items: flex-end !important;
// }

/**
 * Pack items around the center
 *
 * 设置弹性容器轴线对齐方式为：居中排列
 */
// .align-items-center {
//   align-items: center !important;
// }

/**
 * Baseline alignment
 *
 * 设置弹性容器轴线对齐方式为：基线对齐
 */
// .align-items-baseline {
//   align-items: baseline !important;
// }

/**
 * If the combined size of the items is less than the size of the alignment container, any auto-sized items have their size increased equally (not proportionally), while still respecting the constraints imposed by max-height/max-width (or equivalent functionality), so that the combined size exactly fills the alignment container.
 *
 * 设置弹性容器轴线对齐方式为：元素被拉伸以适应容器
 */
// .align-items-stretch {
//   align-items: stretch !important;
// }

/**
 * Pack flex items from the start
 *
 * 设置弹性容器内容项分配方式为：从起始点开始放置flex元素
 */
// .align-content-start {
//   align-content: flex-start !important;
// }

/**
 * Pack flex items from the end
 *
 * 设置弹性容器内容项分配方式为：从终止点开始放置flex元素
 */
// .align-content-end {
//   align-content: flex-end !important;
// }

/**
 * Pack items around the center
 *
 * 设置弹性容器内容项分配方式为：将项目放置在中点
 */
// .align-content-center {
//   align-content: center !important;
// }

/**
 * Distribute items evenly, The first item is flush with the start, the last is flush with the end
 *
 * 设置弹性容器内容项分配方式为：均匀分布项目第一项与起始点齐平，最后一项与终止点齐平
 */
// .align-content-between {
//   align-content: space-between !important;
// }

/**
 * Distribute items evenly, Items have a half-size space, on either end
 *
 * 设置弹性容器内容项分配方式为：均匀分布项目项目在两端有一半大小的空间
 */
// .align-content-around {
//   align-content: space-around !important;
// }

/**
 * Distribute items evenly, Stretch 'auto'-sized items to fit the container
 *
 * 设置弹性容器内容项分配方式为：均匀分布项目拉伸‘自动’-大小的项目以充满容器
 */
.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}

/**
 * 让所有弹性盒模型对象的子元素都有相同的长度，且忽略它们内部的内容
 */
// .flex-1 {
//   flex: 1 !important;
// }

// .flex-row {
//   flex-direction: row !important;
// }

/**
 * 设置弹性盒模型对象的子元素为垂直显示
 */
// .flex-column {
//   flex-direction: column !important;
// }

/**
 * 设置弹性盒模型对象的子元素为垂直相反显示
 */
// .flex-column-reverse {
//   flex-direction: column-reverse !important;
// }

/**
 * 设置弹性盒模型对象的子元素在必要的时候拆行
 */
// .flex-wrap {
//   flex-wrap: wrap !important;
// }

/**
 * 设置弹性盒模型对象的子元素不拆行或不拆列
 */
// .flex-nowrap {
//   flex-wrap: nowrap !important;
// }
// .flex-shrink-0 {
//   flex-shrink: 0 !important;
// }
// .flex-shrink-1 {
//   flex-shrink: 1 !important;
// }
// .flex-grow-0 {
//   flex-grow: 0 !important;
// }
// .flex-grow-1 {
//   flex-grow: 1 !important;
// }

/**
 * Vertical and centered
 *
 * 垂直并居中
 */
// .flex-center {
//   display: flex !important;
//   align-items: center !important;
// }

/**
 * Vertical and centered, and there is a space between the main axis elements (for example: the two elements are on the left and right sides, and the middle is left blank)
 *
 * 垂直并居中，且主轴元素之间留有空白（例如：两个元素分别在左右两边，中间留空）
 */
// .flex-center-between {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: space-between !important;
// }
