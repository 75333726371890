/// 主要配置放在theme/ant.less文件中，通过覆盖antd样式变量的方式修改
/// 这里仅用于补充一些无法覆盖的样式
///
.ant-tabs,
.ant-tabs-top {
  > .ant-tabs-nav {
    &::before {
      @apply border-c-gs;
    }

    // 路由tab样式
    .ant-tabs-tab,
    .ant-tabs-tab a {
      @apply text-l;

      .ant-tabs-tab-btn:focus,
      .ant-tabs-tab:active {
        @apply text-c-d1;
      }
    }
  }
}

/// 垂直方向
.ant-tabs-left {
  > .ant-tabs-nav {
    padding-top: 30px;
    .ant-tabs-tab .ant-tabs-tab-btn {
      line-height: 32px;
    }
  }
  > .ant-tabs-content-holder {
    @apply border-l border-solid border-c-gs;
    .ant-tabs-content > .ant-tabs-tabpane {
      padding-left: 0;
    }
  }
}
