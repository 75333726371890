@charset "utf-8";

@mixin font($font-size: 12px, $color: #000, $font-weight: normal) {
  font-size: $font-size;
  color: $color;
  font-weight: $font-weight;
}

/**
 * @module 文本
 * @description 链接处理方法
 * @method link
 * @version 1.0.0
 * @param {Color} $color 定义链接颜色 <1.0.0>
 */
@mixin link($color: map-get($base, link-color)) {
  color: $color;
  cursor: pointer;

  &:active {
    opacity: 0.5;
  }
}

/**
 * @module 文本
 * @description 文本碰到边界是否换行
 * @method wrap
 * @version 1.0.0
 * @param {Boolean} $is-wrap 定义文本是否换行，默认值：true <2.0.0>
 */
@mixin wrap($is-wrap: true) {
  @if $is-wrap==true {
    word-wrap: break-word;
    word-break: break-all;
  } @else {
    white-space: nowrap;
  }
}

/**
 * @module 文本
 * @description 单行文本溢出时显示省略号
 * @method ellipsis
 * @version 1.0.0
 * @param {Length} $width 定义容器的宽度，默认值：null <2.0.0>
 * @param {Integer} $line-clamp 定义需要显示的行数，默认值：1（即使用单行溢出的处理方案），需要注意的是本参数只支持webkit内核 <2.1.2>
 */
@mixin ellipsis($width: null, $line-clamp: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  width: $width;

  @if abs($line-clamp) >1 {
    // 要使得多行截取生效，display的值只能为-webkit-box
    display: -webkit-box !important;
    -webkit-line-clamp: $line-clamp;
    @include flex-direction(column);
    @include wrap;
  } @else {
    @include wrap(false);
  }
}

/**
 * @module 文本
 * @description 文字隐藏方案
 * @method texthide
 * @version 1.0.0
 * @param {Length} $width 定义容器的宽度，默认值：null <2.0.0>
 */
@mixin texthide($width: null) {
  overflow: hidden;
  white-space: nowrap;
  text-indent: 100%;
  width: $width;
}

/**
 * The inline contents are aligned to the left edge of the line box
 *
 * 文本对齐方式：左边
 */
// .text-left {
//   text-align: left !important;
// }

/**
 * The inline contents are aligned to the center edge of the line box
 *
 * 文本对齐方式：中间
 */
// .text-center {
//   text-align: center !important;
// }

/**
 * The inline contents are aligned to the right edge of the line box
 *
 * 文本对齐方式：左边
 */
// .text-right {
//   text-align: right !important;
// }

/**
 * Set text to lower case
 *
 * 设置文本为小写
 */
// .text-lowercase {
//   text-transform: lowercase !important;
// }

/**
 * Set text to upper case
 *
 * 设置文本为大写
 */
// .text-uppercase {
//   text-transform: uppercase !important;
// }

/**
 * Is a keyword that converts the first letter of each word to uppercase
 *
 * 设置每个单词以大写字母开头
 */
// .text-capitalize {
//   text-transform: capitalize !important;
// }

/**
 * Set text to strikethrough
 *
 * 设置文本为删除线
 */
// .text-deleted {
//   text-decoration: line-through;
// }

/**
 * Sequences of whitespace will collapse into a single whitespace. Text will never wrap to the next line. The text continues on the same line until a `<br>` tag is encountered
 *
 * 文本不会换行，文本会在在同一行上继续，直到遇到 `<br>` 标签为止
 */
// .text-nowrap {
//   white-space: nowrap !important;
// }

/**
 * Whitespace is preserved by the browser. Text will wrap when necessary, and on line breaks
 *
 * 保留空白符序列，但是正常地进行换行
 */
// .text-wrap {
//   white-space: pre-wrap !important;
// }

/**
 * Normal font weight. Same as `400`
 *
 * 普通字体粗细
 */
// .font-weight-normal {
//   font-weight: normal;
// }

/**
 * Bold font weight. Same as `400`
 *
 * 粗体
 */
// .font-weight-bold {
//   font-weight: 700;
// }

/**
 * Italic font style
 *
 * 斜体
 */
// .font-italic {
//   font-style: italic;
// }

/**
 * `12px` text size
 *
 * `12px` 文本大小
 */
// .text-xs {
//   font-size: 12px !important;
// }

/**
 * `14px` text size
 *
 * `14px` 文本大小
 */
// .text-sm {
//   font-size: 14px !important;
// }

/**
 * `16px` text size
 *
 * `16px` 文本大小
 */
// .text-md {
//   font-size: 16px !important;
// }

/**
 * `18px` text size
 *
 * `18px` 文本大小
 */
// .text-lg {
//   font-size: 18px !important;
// }

/**
 * `22px` text size
 *
 * `22px` 文本大小
 */
// .text-xl {
//   font-size: 22px !important;
// }

/**
 * `28px` text size
 *
 * `28px` 文本大小
 */
// .text-xxl {
//   font-size: 28px !important;
// }

// .text-ellipsis {
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

// .point {
//   cursor: pointer;
// }
