@layer components {
}
.v4-layout-content {
  padding: 30px;
  background: #fff;
  border-radius: 10px;
}

.v4-layout-drawer {
  padding: 30px;
}

.v4-layout-field {
  @apply flex flex-row items-start;
  @apply mb-[12px];

  .v4-layout-field-label {
    @apply text-m text-c-d3;
    @apply flex-shrink-0;
    margin-right: 22px;
  }

  .v4-layout-field-control {
    @apply text-m;
    @apply flex-1;
    .v4-layout-field-row {
      @apply flex flex-row items-center;
    }
    .v4-layout-field-extra {
      @apply mt-2;
      @apply text-s text-c-l2;
    }
  }
}

.v4-layout-form,
.v4-layout-info {
  &:not(:last-child) {
    @apply mb-[36px];
  }
  &-title {
    @apply mb-[16px];
    @apply text-l font-medium;
  }
  &-desc {
    @apply mb-[16px];
    @apply text-s text-c-l2;
  }
}
// 为form下的fields赋一个默认高度
// 水平布局的form
.v4-layout-form:not(.v4-layout-form-vertical) {
  .v4-layout-field {
    .v4-layout-field-label {
      height: $height-lg;
      line-height: $height-lg;
    }
    // 只有有label的时候，control才有默认高度。这样单独使用control时会更灵活
    .v4-layout-field-label + .v4-layout-field-control {
      .v4-layout-field-row {
        min-height: $height-lg;
      }
    }
  }
}
// 垂直布局的form
.v4-layout-form.v4-layout-form-vertical {
  .v4-layout-field {
    display: block;
    margin-bottom: 24px;
    .v4-layout-field-label {
      margin-bottom: 8px;
    }
  }
}

// 为info下的fields赋一个默认高度
.v4-layout-info {
  .v4-layout-field {
    .v4-layout-field-label {
      height: $height-sm;
      line-height: $height-sm;
    }
    .v4-layout-field-control {
      .v4-layout-field-row {
        min-height: $height-sm;
      }
    }
  }
}

.v4-layout-dropdown {
  max-height: 60vh;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  border-radius: 10px;
  overflow-y: auto;
}

.v4-layout-block {
  @apply mb-12;
  &-title {
    @apply flex flex-row items-center justify-between;
    @apply text-xl font-medium;
  }
  &-desc {
    @apply mt-[16px];
    @apply text-s text-c-l2;
  }
  &-content {
    @apply mt-9;
    @apply text-c-d2;
  }
}
.v4-layout-block-sm {
  @apply mb-[36px];
  .v4-layout-block-title {
    @apply text-l;
  }
  .v4-layout-block-desc {
    @apply mt-[16px];
  }
  .v4-layout-block-content {
    @apply mt-[16px];
  }
}

// 列表
.v4-layout-list-item {
  padding: 16px;
  @apply border-b border-c-gs border-solid;
}
// 列表使用ant-table的组件。覆写其样式
::ng-deep .v4-layout-list.ant-table-wrapper {
  &.layout-fixed {
    table {
      table-layout: fixed;
    }
  }

  .ant-table {
    // 所有cell的通用样式
    // nz-table里的<thead>元素无法添加class，所以无法使用v4-layout-list-head实现样式覆写
    // 因此这里认为thead元素可以认为就是v4-layout-list-head
    // v4-layout-list-head仅用于样式继承，实际上没有元素使用该类
    --body-cell-default-padding: 16px 0 16px 16px;
    --head-cell-default-bottom-border-color: #e2e5e9;

    // 表头
    .v4-layout-list-head > tr > th,
    .ant-table-thead > tr > th,
    .v4-layout-list-body > tr > td {
      //@extend .v4-layout-list-item;
      padding: var(--body-cell-default-padding);
      border-color: var(--head-cell-default-bottom-border-color);
      @apply border-b border-solid;

      // 组与组之间的间距f
      &.edge-p-left {
        padding-left: 16px;
      }
      &.edge-p-right {
        padding-right: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    // body
    .v4-layout-list-body,
    .v4-layout-list-body-tight {
      --head-cell-default-bottom-border-color: #e1e1e140;
    }

    //条纹表格 {
    .v4-layout-list-body-stripe {
      @extend .v4-layout-list-body;
      > tr:nth-child(even) td {
        @apply bg-c-gc;
      }
      > tr:nth-child(odd) td {
        @apply bg-white;
      }
    }

    // 紧凑的列表
    .v4-layout-list-body-tight {
      --body-cell-default-padding: 12px 0 12px 16px;
      @extend .v4-layout-list-body;
    }

    //sorters
    .ant-table-column-sorters {
      justify-content: flex-start;
      .ant-table-column-title {
        flex: none;
        margin-right: 4px;
      }
      .ant-table-column-sorter {
        .ant-table-column-sorter-up + .ant-table-column-sorter-down {
          margin-top: -0.45em;
        }
      }
    }

    // nz-table里的<thead>元素无法添加class，所以无法使用v4-layout-list-head实现样式覆写
    // 因此这里认为thead元素默认就使用的是v4-layout-list-head的样式
    // v4-layout-list-head仅用于样式继承，实际上没有元素使用该类
    //.ant-table-thead {
    //  @extend .v4-layout-list-head;
    //}

    // group 和 header 的cell样式
    tr.v4-layout-header-row,
    tr.v4-layout-group-row {
      --group-cell-default-color: #{$c-l2};
      --group-cell-default-weight: 400;

      td.v4-layout-header-col,
      th.v4-layout-header-col,
      td.v4-layout-group-col,
      th.v4-layout-group-col {
        padding-top: 0;
        padding-bottom: 0;
        height: 48px;
        line-height: 48px;
        @apply text-s;
        font-weight: var(--group-cell-default-weight);
        color: var(--group-cell-default-color);
      }
    }

    // 表头的 group 行
    tr.v4-layout-group-row {
      --head-cell-default-bottom-border-color: #e1e1e140;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 48px 0 0;
  }
}

// empty
.v4-layout-empty {
  padding: 24px 24px 30px;
  text-align: center;

  &-img {
    margin-bottom: 30px;
    // width: 60px;
    height: 60px;
    @apply rounded-full;
  }

  &-content {
    margin-bottom: 30px;
    &-title {
      @apply text-l;
    }
    &-desc {
      margin-top: 16px;
      @apply text-s;
    }
  }
  &-footer {
  }
}

// 多行文本
.v4-layout-text-lines {
  .v4-layout-text-line {
    margin-top: 8px;
    @apply text-s;
  }
  .v4-layout-text-line:first-child {
    margin-top: 0;
    @apply text-m;
  }
}

// drawer container
.v4-layout-drawer-container {
  height: calc(100vh - 131px);
  @apply flex flex-col;
  &-body {
    flex: 1;
  }
  &-footer {
    padding-top: 30px;
  }
}

// 大型操作/信息区块
.v4-layout-block-card {
  padding: 30px;
  @apply flex flex-row items-center;
  @apply bg-c-gc;
  @apply border-c-gs border border-solid rounded-[10px];

  &-img {
    flex-shrink: 0;
    margin-right: 30px;
  }

  &-title {
    @apply text-xl font-medium;
  }
  &-desc {
    margin-top: 18px;
    @apply text-m;
  }
  &-footer {
    margin-top: 30px;
  }
}
.v4-layout-block-card-lg {
  @extend .v4-layout-block-card;

  &-title {
    @apply text-xxl font-medium;
  }
  &-desc {
    @apply text-l;
  }
}
