/// 主要配置放在theme/ant.less文件中，通过覆盖antd样式变量的方式修改
/// 这里仅用于补充一些无法覆盖的样式
///
/// button
.ant-btn {
  $primary-hover-color: #498ff7;

  // 文字按钮不设最小宽度
  &:not(.ant-btn-text):not(.ant-btn-link):not(.no-min-width) {
    min-width: 80px;
  }
  // default样式的按钮
  &:not(.ant-btn-text):not(.ant-btn-link) {
    &:hover,
    &:focus,
    &:active {
      @apply border-c-l1 text-c-d2;
    }
    &[disabled],
    &[disabled]:hover {
      @apply bg-c-w border-c-gb text-c-l1;
    }

    // primary类型的按钮
    &.ant-btn-primary {
      &:hover,
      &:focus,
      &:active {
        @apply text-c-w;
        border-color: $primary-hover-color;
        background: $primary-hover-color;
      }
      &[disabled],
      &[disabled]:hover {
        @apply bg-c-gc border-c-gb text-c-l1;
      }
    }
  }

  // link类型的按钮
  &-text {
    @apply bg-c-w;
    @apply border-none;
    @apply text-c-b;
    &:hover {
      @apply border-none;
      @apply bg-c-bl;
      @apply text-c-b;
    }
  }
  &-link {
    padding-left: 0;
    padding-right: 0;

    &.no-style {
      height: auto;
      line-height: 1;
      padding-bottom: 0;
    }
  }
}

/// input
.ant-input {
  @apply text-m;
  border-radius: 4px;
  &:focus,
  &-focused {
    box-shadow: none;
  }
}
.ant-input-number,
.ant-input-affix-wrapper {
  border-radius: 4px;
  &:focus,
  &-focused {
    box-shadow: none;
  }

  // &-input {
  //   border-radius: 4px;
  // }
}
/// select
.ant-select {
  &,
  &-lg {
    font-size: 14px;
  }

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 4px;
    }

    &.ant-select-focused:not(.ant-select-disabled) {
      .ant-select-selector {
        box-shadow: none;
      }
    }
  }
}
/// select multiple
.ant-select-multiple {
  .ant-select-selection-item {
    align-items: center;
  }
}

/// radio
.ant-radio-wrapper {
  align-items: center;

  .ant-radio {
    .ant-radio-inner {
      @apply border-c-gb;
    }
    &.ant-radio-checked .ant-radio-inner {
      @apply border-c-l1;
    }

    .ant-radio-input:focus + .ant-radio-inner {
      box-shadow: none;
    }
  }
}

/// radio button
.ant-radio-group {
  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0;
  }
}

/// checkbox
.ant-checkbox-wrapper {
  align-items: center;
  .ant-checkbox {
    top: 0;
  }
}
