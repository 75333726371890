/* You can add global styles to this file, and also import other style files */
@use "sass:math";
@import "mixin";
@import "~@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;
// @import "./v4/variable.css";np
@import "./v4/index.scss";

// @font-face {
//   font-family: MulishVariableFont;
//   src: url(/app/assets/font/Mulish-VariableFont_wght.ttf);
// }

@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-ExtraLight.ttf);
  font-weight: 200;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-Light.ttf);
  font-weight: 300;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-Bold.ttf);
  font-weight: 700;
}
@font-face {
  font-family: PlusJakartaSans;
  src: url(/app/assets/font/PlusJakartaSans-ExtraBold.ttf);
  font-weight: 800;
}

.acl__hide {
  display: none !important;
}

//国际版客服气泡
.woot-widget-bubble.woot-elements--right {
  right: 12px !important;
  bottom: 12px;
  height: 40px;
  width: 40px;

  svg {
    height: 18px;
    width: 18px;
    margin: 10px;
  }
}

.woot-widget-holder.woot-elements--right {
  right: 12px !important;
  bottom: 64px;
}

.woot-widget-bubble.woot--close {
  &::before,
  &::after {
    width: 2px;
    height: 15px;
    left: 19px;
    top: 13px;
  }
}

body.host-body {
  word-spacing: 0.12rem;
  font-family:
    PlusJakartaSans,
    MulishVariableFont,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    segoe ui,
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
}

// 暗色主题的popover
.dark-popover {
  .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ant-popover-content > .ant-popover-inner {
    background-color: #565e6b;
    border-color: #565e6b;
  }
}

// index 页面的service modal样式
// 命名方式 host + 路由名称 + 类名称

.host-shop-house-list .ant-modal-content {
  .ant-modal-body {
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
  }
}

.host-index-user-info-popover {
  cursor: pointer;

  .user-info-popover-item {
    height: 32px;
    line-height: 32px;
    // border-bottom: 1px solid #e9e7e7;
    // padding-top: 11px;

    .user-info-popover-item-center {
      a {
        font-size: 12px;
        color: #111112;
        letter-spacing: 0;
      }

      a:hover {
        color: #2c80f0;
      }
    }
  }

  .user-info-popover-item-bottom {
    height: 40px;
    padding-top: 11px;

    .user-info-popover-item-center {
      a {
        font-size: 12px;
        color: #111112;
        letter-spacing: 0;
      }

      a:hover {
        color: #2c80f0;
      }
    }
  }
}

.host-house-status-ical-modal {
  .body {
    font-size: 12px;
    color: #111112;
  }

  .footer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ant-checkbox-wrapper {
      font-size: 12px;
      // color: #a2a2a2;

      .ant-checkbox-inner {
        // background: #f1f1f1;
        border-radius: 2px;

        &:after {
          border-color: #ffffff;
        }
      }

      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890ff;
        border-color: #1890ff;
      }
    }

    .ant-btn {
      margin-left: 10px;
    }
  }
}

.ant-modal-content .ant-pagination.mini a.ant-pagination-item-link.ng-star-inserted .anticon {
  padding-top: 6px;
}

// 所有的日期控件隐藏掉选择的日期展示，修正图标相对位置
.ant-calendar-range-middle {
  padding: 0;
}

.ant-calendar-input-wrap {
  display: none;
}

.ant-calendar-header {
  .ant-calendar-prev-year-btn,
  .ant-calendar-prev-month-btn,
  .ant-calendar-next-month-btn,
  .ant-calendar-next-year-btn {
    top: 17px;
  }
}

// 日历侧边栏日期选择框
.host-operate-sidebar-date-picker {
  top: 34px;
}

// zorro 8.5.1出现的bug，select的margin被属性强行替换，导致显示偏差，这里暂时强行矫正
.ant-select-selection-selected-value {
  margin-right: 20px;
}

.ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 11px;
  height: 32px;
  line-height: 32px;
}

// 客服弹窗
.host-customer-service-client-modal {
  width: 400px;
  height: 600px;
  right: 40px;
  bottom: 40px;
  left: auto;
  top: auto;

  .ant-modal {
    position: fixed;
    padding-bottom: 0;
    right: 40;
    bottom: 40;
    top: auto;
  }

  @media (max-width: 520px) {
    width: calc(100vw - 16px);
    right: 8px;
    .ant-modal {
      right: 8px;
      margin: 0 auto;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
}

.cdk-global-overlay-wrapper {
  overflow-y: auto;
}

.host-no-data-img-small {
  width: 64px;
  // height: 40px;
  margin-bottom: 16px;
}

.host-no-data-img-large {
  width: 96px;
  // height: 60px;
  margin-bottom: 16px;
}

// 以下两个修正Zorro8.5版本模态框和分页按钮的位置错位
::ng-deep .ant-modal-close-x {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:host ::ng-deep .ant-pagination-item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

::ng-deep .ant-table-thead > tr > th {
  .ant-table-column-sorter {
    .ant-table-column-sorter-inner-full {
      margin-top: -0.4em;

      .ant-table-column-sorter-down {
        margin-top: 0;
      }
    }
  }
}
