/// filter 相关

// 过滤器样式
.v4-filter {
  @apply flex flex-row items-center;
  @apply px-3;
  @apply bg-c-gc;
  @apply border-c-gb border-t border-r border-b border-l border-solid rounded-[4px];
  @apply text-c-d3 text-m whitespace-nowrap text-ellipsis;
  @apply cursor-pointer;
  height: 32px;
  box-shadow: none;

  // 可选中的筛选器选中状态
  //&.v4-filter-selectable {
  //  &.selected,
  //  &:hover {
  //    @apply text-c-b;
  //    @apply bg-c-bl;
  //    @apply border-c-b;
  //  }
  //}
}

.v4-filter-selectable {
  @extend .v4-filter;
  &.selected {
    @apply text-c-b;
    @apply bg-c-bl;
    @apply border-c-b;
  }
  &:hover {
    @apply text-c-d2 text-m;
    @apply border-c-l1;
  }
}
// 过滤器列表
.v4-filter-list {
  @apply flex flex-row items-center;

  .v4-filter-item:not(:last-of-type) {
    @apply mr-3;
  }
}

// 覆写自定义组件的样式
// 目前自定义的select选择器基本使用的是v4-custom-select的包装，其中用了ant-select的类，用于与ant的select组件保持一致
// 在父组件添加v4-filter-style，可以使自定义组件内部的样式变为v4-filter的样式，从而与ant的select组件显示相同效果
// 对于原生的ant的select组件，可以直接添加v4-fitlter类，使其变为filter的样式
::ng-deep .v4-filter-style {
  display: flex;
  // select
  .ant-select,
  .ant-select-focused {
    &:not(.ant-select-customize-input):not(.ant-select-disabled) {
      .ant-select-selector {
        @apply v4-filter;
        text-align: center;

        // placeholder
        .ant-select-selection-placeholder {
          @apply text-c-d3 text-m;
          // 由于不需要显示arrow，所以这里将pr置空
          padding-right: 0;
        }

        // 选择之后的文字。由于不需要显示arrow，所以这里将pr置空
        .ant-select-selection-item {
          @apply text-c-d2 text-m;
          padding-right: 0;
        }
      }
      .ant-select-arrow {
        display: none;
      }
    }
    // 位于激活态和hover态的select
    &:hover,
    &.ant-select-active {
      &:not(.ant-select-customize-input):not(.ant-select-disabled) {
        .ant-select-selector {
          @apply border-c-l1;
        }
      }
    }
  }

  // radio group
  .ant-radio-group .ant-radio-button-wrapper {
    @apply bg-c-gc;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    @apply text-c-b;
    @apply bg-c-bl;
    @apply border-c-b;
    box-shadow: none;
    // background-color: $c-bl !important;
  }
}

/// option相关
// 可选中的列表选项
.v4-option {
  @apply v4-filter;
  background-color: #fff;
  border-radius: 16px;
  &.selected,
  &:hover {
    @apply text-c-b;
    @apply bg-c-bl;
    @apply border-c-b;
  }
  &.disabled {
    @apply text-c-l1;
    @apply bg-c-gc;
    @apply border-c-gb;
  }
}
.v4-option-list {
  @apply flex flex-row items-center flex-wrap;

  .v4-option-item {
    @apply mr-3 mb-3;
  }
}

// tag
.v4-tag {
  // @apply text-xs text-c-d2;
  @apply text-xs;
  @apply px-2;
  @apply cursor-pointer;
  white-space: nowrap;
  color: white;
  padding: 0 8px;
  height: 20px;
  line-height: 20px;
  border-radius: 4px;
}
.v4-tag-list {
  @apply flex flex-row flex-wrap;
}

// checkbox
// 默认央视
.v4-checkbox.ant-checkbox-wrapper {
}
.v4-checkbox-light.ant-checkbox-wrapper {
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #d9d9d9;
    background-color: #fff;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #2c80f0;
  }
}

@layer components {
}
