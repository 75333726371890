// nz-alert
.ant-alert {
  padding: 12px;
  border-radius: 12px;

  .ant-alert-icon {
    margin-right: 12px;
  }
  .ant-alert-content {
    .ant-alert-message {
      line-height: 20px;
    }
  }

  // 对应 '提示块-强提示'
  &.ant-alert-error {
    background-color: #fff;
    @apply border-c-o;
    .ant-alert-content {
      .ant-alert-message {
        color: $c-o1;
        // @apply text-c-o1;
      }
    }
  }

  // 对应 '提示块-强烈警示'
  &.ant-alert-warning {
    background-color: #fff;
    @apply border-c-r;
    .ant-alert-icon {
      color: $c-r;
    }
    .ant-alert-content {
      .ant-alert-message {
        color: $c-r;
      }
    }
  }

  &.ant-alert-success {
    background-color: #fff;
    border-color: #48ca93;
    .ant-alert-icon {
      color: #48ca93;
    }
    .ant-alert-content {
      .ant-alert-message {
        color: #48ca93;
      }
    }
  }
}

// notification
.ant-notification {
  .ant-notification-notice-message {
    color: #272b30;
  }
  .ant-notification-notice-description {
    color: #586167;
  }
}

// modal
.ant-modal .ant-modal-content {
  padding: 0 30px;
  border-radius: 10px;

  .ant-modal-footer {
    padding: 0 0 30px;
    border-top: none;

    .ant-btn {
      // ant-btn-lg的样式 @todo 需要更好的处理方式
      height: 42px;
      padding: 7.4px 20px;
      font-size: 16px;
      border-radius: 4px;
    }
  }
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 30px;
    .ant-modal-confirm-body-wrapper {
      .ant-modal-confirm-body {
        @apply flex flex-col items-center;

        > .anticon {
          margin-right: 0;
          margin-bottom: 30px;
          font-size: 40px;
        }

        //标题-nzTitle
        .ant-modal-confirm-title {
          @apply text-l text-center;
        }

        //描述-nzContent
        .ant-modal-confirm-title + .ant-modal-confirm-content {
          margin-left: 0;
          margin-top: 30px;
          text-align: center;
          @apply text-s;
        }

        @media (max-width: 768px) {
          .ant-modal-confirm-title + .ant-modal-confirm-content {
            margin-top: 15px;
          }
        }
      }
      .ant-modal-confirm-btns {
        float: none;
        margin-top: 30px;
        text-align: center;

        .ant-btn {
          // ant-btn-lg的样式 @todo 需要更好的处理方式
          height: 42px;
          padding: 7.4px 20px;
          font-size: 16px;
          border-radius: 4px;
        }
      }
    }
  }
}

// drawer
.ant-drawer .ant-drawer-content {
  padding: 0 30px;
}
.ant-drawer.ant-drawer-open.no-mask {
  width: 0;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  animation: none;
  transition: none;
}

//
.ant-modal .ant-modal-content,
.ant-drawer .ant-drawer-content {
  .ant-modal-close-x {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-close,
  .ant-drawer-close {
    .anticon {
      background-image: url(/app/assets/v4/icon-close.svg);
      background-size: cover;
      width: 16px;
      height: 16px;
    }
    svg {
      display: none;
    }
  }
}
